<!--- DO NOT CHANGE THIS FILE -->
<div class="container" style="text-align: center;" *ngIf="!showTenants">
    <br /><br />
    <img src="/assets/343.svg" height="150" width="150"> <br /><br />

    Authenticating...

    <span *ngIf="canRetry">
        <br /><br />
        Taking too long? 
        <a (click)="logout()">
            Click here to signout and try again.
        </a>
    </span>
    
</div>

<div class="container" style="max-width: 300pt;" *ngIf="showTenants">
    <login-tenant-selection></login-tenant-selection>
  </div>
  
  