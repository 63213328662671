/// /////////////////////////////////////////////
/// DO NOT CHANGE THIS FILE.      //////////////
/// /////////////////////////////////////////////
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcastService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import { TENANTS } from '../../tenants.constants';

import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'login',
  templateUrl: './login.template.html',
})
export class LoginComponent implements OnInit {
  public isLoggedIn: boolean;
  public showTenants: boolean = false;
  public spartanToken: string;
  public canRetry: boolean = false;
  public subscription: Subscription;

  constructor(private auth: AuthService, private router: Router, private route: ActivatedRoute, private broadcastService: BroadcastService) { }

  public ngOnInit() {
    this.subscription = this.broadcastService.subscribe('msal:loginSuccess',
      (payload): void => {
        console.debug(`login success ${JSON.stringify(payload)}`);
        this.isLoggedIn = true;
      });

    if (this.route.snapshot.queryParams.useMsft) {
      console.debug('login: MSFT TENANT OVERRIDE');
      const msftTenant = TENANTS.filter(t => t.id === AuthService.msftTenantId)[0];
      this.auth.changeTenantId(msftTenant.id);
      localStorage.setItem('allowLegacyTenant', msftTenant.id);
    }

    console.debug('login on init');
    this.auth.handleReturn(); // handles return if any

    console.debug(`login: is logged in?${this.isLoggedIn}`);

    this.isLoggedIn = this.auth.check();

    if (this.isLoggedIn) {
      console.debug('login: fetching ST');
      const currentTenant = TENANTS.filter(t => t.id === this.auth.aadTenantId)[0];
      let environment = localStorage.getItem('lastEnv');
      const lastTenantId = localStorage.getItem('tenant-id');
      if (!lastTenantId || lastTenantId !== currentTenant.id || !environment) {
        environment = currentTenant.defaultEnv;
      }
      const stv4Host = `https://settings${currentTenant.stv4Affix}.svc.halowaypoint.com`;
      const stv4Aud = environment.toLocaleLowerCase() === 'prod' ? 'https://settings.svc.halowaypoint.com/spartan-token' : 'https://settings.test.svc.halowaypoint.com/spartan-token';

      this.auth.getSpartanToken(stv4Aud, stv4Host).then(token => {
        this.spartanToken = token.SpartanToken;
        const redirectToPath = localStorage.getItem('post-login-path');
        if (redirectToPath) {
          const url = `${window.location.protocol}//${window.location.host}/${redirectToPath}`;
          console.debug(`redirecting to ${url}`);
          window.location.replace(url);
        } else {
          console.debug('defaulting to /home');
          this.router.navigate([`/guard/${environment}/hi/home`]);
        }
      });
    } else {
      const requestedTenant = localStorage.getItem('nextTenant');
      if (requestedTenant) {
        localStorage.removeItem('nextTenant');
        this.loginStart();
      } else {
        this.showTenants = true;
      }
      this.showTenants = !localStorage.getItem('tenant-id');
    }

    setTimeout(() => {
      this.canRetry = true;
    }, 1000);
  }

  private loginStart(): void {
    this.auth.login().then();
    this.auth.handleReturn();
  }

  public logout(): void {
    localStorage.clear();
    window.location.replace(`${window.location.protocol}//${window.location.host}`);
  }

  public ngOnDestroy() {
    // disconnect from broadcast service on component destroy
    this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
